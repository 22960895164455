#centerDiv {
    width: 70%;
    max-width: 40rem;
    height: 500;
    color: white;
    margin: auto;
}

#largeCenterDiv {
    width: 70%;
    max-width: 100rem;
    height: 500;
    color: white;
    margin: auto;
}

#blogBox {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1440px; /* whatever you desire */
    margin-left: auto; /* center the container */
    margin-right: auto; /* center the container */
}

#blogBox img {
    width: 100%;
    object-fit: cover;
}


@media only screen and (max-width: 480px) {
    /* For Mobile */
    #centerDiv {
        width: 90%;
    }

    #title {
        font-size: 3.14rem;
    }

    #largeCenterDiv {
        width: 90%;
    }

    #subTitle {
        font-size: 1.725rem !important;
    }
}